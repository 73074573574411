import { Box, Flex, Skeleton, Text, useColorModeValue } from '@chakra-ui/react';
import React from 'react';

import config from 'configs/app';
import useApiQuery from 'lib/api/useApiQuery';
import { HOMEPAGE_STATS } from 'stubs/stats';
//import Hint from 'ui/shared/Hint';
import IconSvg from 'ui/shared/IconSvg';

import ChainIndicatorChartContainer from './ChainIndicatorChartContainer';
import ChainIndicatorItem from './ChainIndicatorItem';
import useFetchChartData from './useFetchChartData';
import INDICATORS from './utils/indicators';

const indicators = INDICATORS.filter(({ id }) =>
  config.UI.homepage.charts.includes(id),
).sort((a, b) => {
  if (
    config.UI.homepage.charts.indexOf(a.id) >
    config.UI.homepage.charts.indexOf(b.id)
  ) {
    return 1;
  }

  if (
    config.UI.homepage.charts.indexOf(a.id) <
    config.UI.homepage.charts.indexOf(b.id)
  ) {
    return -1;
  }

  return 0;
});

const ChainIndicators = () => {
  const [ selectedIndicator, selectIndicator ] = React.useState(
    indicators[0]?.id,
  );
  const indicator = indicators.find(({ id }) => id === selectedIndicator);

  const queryResult = useFetchChartData(indicator);
  const statsQueryResult = useApiQuery('stats', {
    queryOptions: {
      refetchOnMount: false,
      placeholderData: HOMEPAGE_STATS,
    },
  });

  //const bgColorDesktop = useColorModeValue('white', 'gray.900'); // *OhoDesign - remove bg
  const bgColorMobile = useColorModeValue('white', 'black');
  //const listBgColorDesktop = useColorModeValue('gray.50', 'black'); // *OhoDesign - remove bg
  //const listBgColorMobile = useColorModeValue('gray.50', 'gray.900'); // *OhoDesign - remove bg

  if (indicators.length === 0) {
    return null;
  }

  const valueTitle = (() => {
    if (statsQueryResult.isPlaceholderData) {
      return (
        <Skeleton h="36px" w="215px" mt={ 0 } mb={ 0 }/>
      ); /*OhoDesign - h 48 -> 36, mt 3 -> 0, mb 4 -> 0 */
    }

    if (!statsQueryResult.data) {
      return (
        <Text mt={ 0 } mb={ 0 }>
          { /*OhoDesign - mt 3 -> 0, mb 4 -> 0 */ }
          There is no data
        </Text>
      );
    }

    return (
      <Text
        fontWeight={ 600 }
        fontFamily="heading"
        fontSize="36px" /*OhoDesign - 48 -> 36 */
        lineHeight="36px" /*OhoDesign - 48 -> 36 */
        mt={ 0 } /*OhoDesign - 3 -> 0 */
      >
        { indicator?.value(statsQueryResult.data) }
      </Text>
    );
  })();

  const valueDiff = (() => {
    if (!statsQueryResult.data || !indicator?.valueDiff) {
      return null;
    }

    const diff = indicator.valueDiff(statsQueryResult.data);
    if (diff === undefined || diff === null) {
      return null;
    }

    const diffColor = diff >= 0 ? 'green.500' : 'red.500';

    return (
      <Skeleton
        isLoaded={ !statsQueryResult.isPlaceholderData }
        display="flex"
        alignItems="center"
        color={ diffColor }
        //ml={2}
        mt={ 2 } // *OhoDesign - change ml -> mt
      >
        <IconSvg
          name="arrows/up-head"
          boxSize={ 5 }
          mr={ 1 }
          transform={ diff < 0 ? 'rotate(180deg)' : 'rotate(0)' }
        />
        <Text color={ diffColor } fontWeight={ 600 }>
          { diff }%
        </Text>
      </Skeleton>
    );
  })();

  return (
    <Flex

      /* p={{ base: 0, lg: 8 }} */ // *OhoDesign - remove padding
      borderRadius={{
        base: 'none',
        lg: 'none',
      }} // *OhoDesign - lg: 'lg' -> none
      /* boxShadow={{ base: 'none', lg: 'xl' }} */ // *OhoDesign - remove box shadow
      /* bgColor={{ base: bgColorMobile, lg: bgColorDesktop }}*/ // *OhoDesign - change bg
      bgColor={{ base: bgColorMobile, lg: bgColorMobile }}
      columnGap={ 6 }
      rowGap={ 0 }
      flexDir={{ base: 'column', lg: 'row' }}
      w="100%"
      alignItems="stretch"
      mt={{ base: 0, lg: 0 }} // *OhoDesign - base 1 -> 0, lg 3 -> 0
    >
      <Flex
        flexGrow={ 1 }
        flexDir="column"
        order={{ base: 2, lg: 1 }}

        /*p={{ base: 6, lg: 0 }} */ // *OhoDesign - remove chart padding
      >
        <Flex
          marginTop="10px"
          justifyContent="space-between"
          flexDir={{ base: 'row', lg: 'row' }}
        >
          <Box mb="6px">
            { valueTitle }
            { valueDiff }
          </Box>
          { indicators.length > 1 && (
            <Flex
              flexShrink={ 0 }
              flexDir={{ base: 'column', lg: 'row' }}
              mt="6px" // OhoDesign
              mb="0px" // OhoDesign
              as="ul"

              /*p={3}*/ // OhoDesign - remove chart padding
              borderRadius="32px"
              // bgColor={{ base: listBgColorMobile, lg: listBgColorDesktop }} *OhoDesign - remove background color
              rowGap="6px" // OhoDesign - change row gap from {3} to "6px"
              columnGap="6px" // OhoDesign - add column gap "6px"
              order={{ base: 1, lg: 2 }}
            >
              { indicators.map((indicator) => (
                <ChainIndicatorItem
                  key={ indicator.id }
                  { ...indicator }
                  isSelected={ selectedIndicator === indicator.id }
                  onClick={ selectIndicator }
                  stats={ statsQueryResult }
                />
              )) }
            </Flex>
          ) }
          { /*
          <Text fontWeight={500} fontFamily="heading" fontSize="lg">
            {indicator?.title}
          </Text>
          {indicator?.hint && <Hint label={indicator.hint} ml={1} />}
          */ }
          { /* OhoDesign - remove old header and hint*/ }
        </Flex>
        <ChainIndicatorChartContainer { ...queryResult }/>
      </Flex>
    </Flex>
  );
};

export default ChainIndicators;
