import { Box, Flex, Heading } from '@chakra-ui/react';
import React from 'react';

import config from 'configs/app';
import ChainIndicators from 'ui/home/indicators/ChainIndicators';
import LatestBlocks from 'ui/home/LatestBlocks';
import LatestZkEvmL2Batches from 'ui/home/LatestZkEvmL2Batches';
import Stats from 'ui/home/Stats';
import Transactions from 'ui/home/Transactions';
//import AdBanner from 'ui/shared/ad/AdBanner';
import ProfileMenuDesktop from 'ui/snippets/profileMenu/ProfileMenuDesktop';
import SearchBar from 'ui/snippets/searchBar/SearchBar';
import WalletMenuDesktop from 'ui/snippets/walletMenu/WalletMenuDesktop';

const rollupFeature = config.features.rollup;

const Home = () => {
  return (
    <Box as="main" marginTop="-24px">
      { /*OhoDesign - add margitop 0 -> -24px */ }
      <Box
        w="100%"
        background={ config.UI.homepage.plate.background }
        borderRadius={{
          base: 'none',
          lg: 'none',
        }} /*OhoDesign - base: md, lg: xl -> none, none */
        //px={{ base: 4, lg: 10 }}
        //py={{ base: 3, lg: 8 }}
        minW={{ base: 'unset', lg: '900px' }}
        paddingTop={{ base: '12px', lg: '16px' }}
        paddingBottom={{ base: '8px', lg: '12px' }}
        paddingRight={{ base: '16px', lg: '20px' }}
        paddingLeft={{ base: '16px', lg: '20px' }}
        height={{
          base: '334px',
          lg: '220px',
        }} /* OhoDesign - reduce searchbox height */
        data-label="hero plate"
      >
        <Heading
          as="h1"
          textAlign={{ base: 'center', lg: 'center' }}
          size={{ base: 'md', lg: 'xl' }}
          //fontSize={{ base: '18px', lg: '40px' }}
          lineHeight={{ base: '20px', lg: '32px' }}
          fontWeight={ 500 }
          letterSpacing="1px" // *Ohodesign - add letter spacing
          //color={config.UI.homepage.plate.textColor}
          color="#FFFFFF"
          paddingTop={{ base: '16px', lg: '16px' }}
          paddingBottom={{ base: '14px', lg: '24px' }}
        >
          Layer 1-for-All Blockchain
          { /*{config.meta.seo.enhancedDataEnabled
            ? `${config.chain.name} blockchain explorer`
            : `${config.chain.name} explorer`}*/ }
        </Heading>
        <Flex
          mb={{ base: 0, lg: 0 }} //*Ohodesign - base: 2, lg: 6 -> base: 0, lg: 0
          justifyContent="space-between"
          alignItems="center"
        >
          <SearchBar isHomepage/>
          { config.UI.navigation.layout === 'vertical' && (
            <Box display={{ base: 'none', lg: 'flex' }}>
              { config.features.account.isEnabled && (
                <ProfileMenuDesktop isHomePage/>
              ) }
              { config.features.blockchainInteraction.isEnabled && (
                <WalletMenuDesktop isHomePage/>
              ) }
            </Box>
          ) }
        </Flex>
        <Stats/>
      </Box>
      <ChainIndicators/>
      { /* <AdBanner mt={6} mx='auto' display='flex' justifyContent='center' />*/ }
      <Flex
        mt={ 6 }
        direction={{ base: 'column', lg: 'row' }}
        columnGap={ 12 }
        rowGap={ 6 }
      >
        { rollupFeature.isEnabled && rollupFeature.type === 'zkEvm' ? (
          <LatestZkEvmL2Batches/>
        ) : (
          <LatestBlocks/>
        ) }
        <Box flexGrow={ 1 }>
          <Transactions/>
        </Box>
      </Flex>
    </Box>
  );
};

export default Home;
