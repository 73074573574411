import { Flex } from '@chakra-ui/react';
import type { UseQueryResult } from '@tanstack/react-query';
import React from 'react';

import type { TimeChartData } from 'ui/shared/chart/types';

import ContentLoader from 'ui/shared/ContentLoader';
import DataFetchAlert from 'ui/shared/DataFetchAlert';

import ChainIndicatorChart from './ChainIndicatorChart';

type Props = UseQueryResult<TimeChartData>;

const ChainIndicatorChartContainer = ({ data, isError, isPending }: Props) => {
  const content = (() => {
    if (isPending) {
      return <ContentLoader mt="auto"/>;
    }

    if (isError) {
      return <DataFetchAlert/>;
    }

    if (data[0].items.length === 0) {
      return <span>no data</span>;
    }

    return <ChainIndicatorChart data={ data }/>;
  })();

  return (
    <Flex
      h={{ base: '160px', lg: '240px' }} //*Ohodesign - change from 150px & auto to 160px and 240px
      minH="160px" //*Ohodesign - change from 150px to 160px
      alignItems="flex-start"
      flexGrow={ 1 }
    >
      { content }
    </Flex>
  );
};

export default React.memo(ChainIndicatorChartContainer);
